export function calculateTotalAmount(transactions) {
    let totalAmount = 0;
    transactions.forEach(transaction => {
        transaction[2].forEach(item => {
            totalAmount += item.amount;

        });
        transactions.forEach(transaction => {
            transaction[3].forEach(item => {
                totalAmount += item.amount;
            
            });
            
        });
    });
    return totalAmount;
}


export function getInvestment(transactions){

    let investmentAmount=0;
        
    let investmentName = ''; // Declare and initialize investmentName

    transactions.forEach(transaction=>{
        transaction[3].forEach(investmentItem=>{
            investmentAmount+=investmentItem.amount;
            investmentName=investmentItem.name;
    
    });
});

return investmentAmount;
};
   
  

export function getIncome(transactions){
    let income =0
    transactions.forEach(transaction => {
        income=transaction[1]

    })
    return income;
}
export function getSavingInBudget(transactions){
    let saving=[];
    let savingName='';
    transactions.forEach(transaction=>{
        transaction[2].forEach(item => {
            if(item.saving===true){
            saving[item.name]= item.amount;
            savingName = item.name;

        }
        });

    })
    return saving;

}

export function getMonthData(transactions, month, year) {
    transactions.filter(entry => {        
        
}    
)
    return transactions.filter(entry => (
        entry[4] === month && entry[5] === year
    ));    
}


export function getAllMonthsTillCurrentMonth() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthsTillCurrentMonth = [];

    for (let i = 1; i <= currentMonth; i++) {
        monthsTillCurrentMonth.push([months[i - 1],currentYear]);
    }

    return monthsTillCurrentMonth;
}


export function getPastMonths() {
    const monthArray = [];

 for (let i = 0; i < 6; i++) {
  let monthDate = new Date();
  monthDate.setMonth(monthDate.getMonth() - i-1);
  let month = monthDate.toLocaleString('default', { month: 'short' });
  let year = monthDate.getFullYear();
  let longMonth = monthDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  monthArray.push({  month, year});
}

return monthArray
}


export function getCurrentMonth(){
const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  const currentDate = new Date();
  
  // Get the current month index (returns a number from 0 to 11, where 0 is January and 11 is December)
  const currentMonthIndex = currentDate.getMonth();
  
  // Get the current month name using the month index
  const currentMonthName = monthNames[currentMonthIndex];
  
  // Get the current year (four-digit year)
  const currentYear = currentDate.getFullYear();
  
  // Display or use the current month name and year
  const MonthYear=[currentMonthName,currentYear]
  return MonthYear;
}

export function ifNaN(variable){
const number=isNaN(variable)?0:variable
return number
}