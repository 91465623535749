import React from "react";
import axios from "axios";
import { useState,useEffect } from "react";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import './notification_settings.css'
function Notification_settings(props) {
    const token = localStorage.getItem('user')
    const email = localStorage.getItem('email_notification') ;
    const whatsapp = localStorage.getItem('whatsapp_notification') ;
    const emailbool = email === "true";
    const  whatsappbool = whatsapp === "true" 
    const [valueemail, setValueEmail] = useState(emailbool);
    const [valuewhatsapp, setValueWhatsapp] = useState(whatsappbool);
    const [expired, setExpired] = useState(false);

    const handleSave = async (e)=>{
        e.preventDefault();
        
        const data ={
            "data": {"email": valueemail,
             "whatsapp": valuewhatsapp},
             "toUpdate":"notification"
         }
  
  const config = {
         method: "post",
         url: "http://68.178.172.104:6010/api/settings",
         headers: { 
             'Authorization': `Bearer ${token}`,  
         },
         data : data  
       };
       try{
        const response=await axios.request(config)
        localStorage.setItem("email_notification",valueemail)
        localStorage.setItem("whatsapp_notification",valuewhatsapp)
      }
      catch (error) {
        console.log(error);
        
        if (error.response.status === 401) {
          setExpired(true);
        }
    }
}

    
    const handleCheckboxChange = (checkboxNumber) => {
        if (checkboxNumber === 1) {
            setValueEmail(!valueemail);
            
            setValueWhatsapp(false); // Uncheck checkbox2 when checkbox1 is checked
            
        } else {
            setValueWhatsapp(!valuewhatsapp);
            setValueEmail(false); // Uncheck checkbox1 when checkbox2 is checked
        }
      };
    
    return (  <div>
         <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Notification settings</label>
                    <RxCross2 onClick={props.close} />
                </div>
                <div className="checkbox-wrapper-13" style={{margin: '20px 15px'}}>
                    
                    <label className="label-in-notification">Email</label>
                        <input 
                            type="checkbox" 
                            id="c1-13"
                            name="notification"
                            className="input-checkbox-in-notification"
                            checked = {valueemail}
                            onChange={() => handleCheckboxChange(1)}
                            />
                           
                </div>
                <div className="checkbox-wrapper-13" style={{margin: '20px 15px'}}>
                    <label className="label-in-notification">Whatsapp</label>
                        <input 
                            type="checkbox" 
                            name="notification"
                            id="c1-13"
                            className="input-checkbox-in-notification"
                            checked={valuewhatsapp}
                            onChange={() => handleCheckboxChange(2)}
                            />
                </div>
                <div className="save-button-in-notification">
                    <button className="save-button" onClick={handleSave}>Save</button>
                </div>
            </form>
        </Modal>
    </div>);
}

export default Notification_settings;