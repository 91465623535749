import './investment.css'
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useState,useEffect } from 'react';
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FaPlus } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function InvestmentPage() {
    const [investment, setInvestment]= useState([]);
    const [statetable,setStatetable] = useState(false);
    const [startDateInvest, setStartDateInvest] = useState("");
    const [endDateInvest, setEndDateInvest] = useState("");
    //const [isEditing,setisEditing]=useState(false)
    const [frequency,setFrequency]=useState("Yearly");
    const [investstate,setInvestState]=useState(false);
    const [InvestFormdata, setInvestFormdata] = useState([]);
    const [amountFormdata, setAmountFormdata] = useState([]);
    const [updatedExpense, setUpdatedExpense] = useState({});
    const [periodicAmount,setPeriodicAmount] = useState("");
    const [dueDate,setDueDate]=useState('')
    const [investmentNameError, setInvestmentNameError] = useState('');
    const [investmentAmountError, setInvestmentAmountError] = useState('');
    const[periodicAmountError,setPeriodicAmountError]=useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [dueDateError, setDueDateError] = useState('');
    const[status,setStatus] = useState("Active");
const token = localStorage.getItem('user')
useEffect(()=>{

        const fetchdata=async()=>{
        const config = {
               method: "post",
               url: "http://68.178.172.104:6010/api/Investment",
               headers: { 
                   'Authorization': `Bearer ${token}`,  
               },  
             };
             try {
              const response = await axios.request(config);
              console.log(response.data)
              // if (response.data.investment && response.data.investment.length > 0) {
              //     setInvestment(response.data.investment);
              // } else {
              //     toast.error("1st Something Went Wrong!");
              // }
          } catch (error) {
              toast.error("2nd Something Went Wrong!");
          }
            }
            fetchdata();
      },[token]);
    const handledeleteinvest = async (id) =>{
        if (id !== null) {
          const newItems = investment.filter((item, index) => index !== id);
          
          setInvestment(newItems);

      }
    }

        const formatDateForExpense = (date) => {
            var options = { year: 'numeric', month: 'short', day: '2-digit' };
            let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
            let parts = formattedDate.split(" ");
            return (parts[1] + "-" + parts[0] + "-" + parts[2]).replace(",","");
        };
        const handleSave = (e) => {
          e.preventDefault();
  
          let hasError = false
  
          if (InvestFormdata.length === 0 || InvestFormdata === '') {
              setInvestmentNameError('Enter investment');
              hasError = true;
          } 
  
          if (amountFormdata === 0 || amountFormdata === "" || amountFormdata.length===0) {
              setInvestmentAmountError('Enter amount');
              hasError = true;
          } 
          if(periodicAmount === '' || periodicAmount === 0|| periodicAmount.length===0){
            setPeriodicAmountError("Enter Periodic Amount");
            hasError = true;
          }
          if(startDateInvest ===""){
            setStartDateError("Enter start Date")
            hasError = true;
          }
          if(endDateInvest ===""){
            setEndDateError("Enter End Date")
            hasError = true;
          }
          if(dueDate ===""){
            setDueDateError("Enter Due Date")
            hasError = true;
          }
          if (startDateInvest > endDateInvest) {
              setStartDateError('The end date should be higher than the start date');
              hasError = true;
          } 
  
          if (hasError) {
            hasError=false;
              return;
          }
          const AddInvestment={name:InvestFormdata,startdate:formatDateForExpense(startDateInvest),enddate:formatDateForExpense(endDateInvest),frequency:frequency,status:status,amount:amountFormdata,periodic_amount:periodicAmount,duedate:formatDateForExpense(dueDate),reminder:false}
          let Listitems = [...investment,AddInvestment]
              setInvestment(Listitems)
              setInvestFormdata("")
              setStartDateInvest("")
              setEndDateInvest("")
              setFrequency("Yearly")
              setStatus("Active")
              setAmountFormdata("")
              setDueDate("")
              setPeriodicAmount("")
              setStatetable(false)
        }

        const handleOnSubmit = async (e) =>{
            e.preventDefault();
           
            const data = new FormData();
            data.append("investment",JSON.stringify(investment))
             const config = {
                method: "post",
                url: "http://68.178.172.104:6010/api/Investment_Submit",
                data:data,
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                  },
              };
              try {
                await axios.request(config);
                toast.success("Investment Added");
            } catch (error) {
                toast.error("Investment Not Submitted");
            }
        }
        const handleEditSave = (index) => {
            const updatedExpenses = [...investment];
            updatedExpenses[index].isEditing = true;
            setInvestment(updatedExpenses);
         };
         const handleCloseEdit = (index) => {
          const updatedExpenses = [...investment];
            updatedExpenses[index].isEditing = false;
            setInvestment(updatedExpenses);

         }
         const handlePopUPClose =()=>{
          setStatetable(false);
          setInvestFormdata("")
              setStartDateInvest("")
              setEndDateInvest("")
              setFrequency("Yearly")
              setStatus("Active")
              setAmountFormdata("")
              setDueDate("")
              setPeriodicAmount("")


         }
         const handlePlus = (index) => {
            const updatedExpenses = [...investment];
            
            updatedExpenses[index] = { ...updatedExpenses[index], ...updatedExpense, isEditing: false};
            setInvestment(updatedExpenses);
            setUpdatedExpense({});
         };
         
         useEffect(() => {
          setInvestmentAmountError("")
         }, [amountFormdata,statetable]);
         useEffect(() => {
          setInvestmentNameError("")
         }, [InvestFormdata,statetable]);
         useEffect(() => {
          setDueDateError("")
         }, [dueDate,statetable]);
         useEffect(() => {
          setStartDateError("")
         }, [startDateInvest,statetable]);
         useEffect(() => {
          setEndDateError("")
         }, [endDateInvest,statetable]);
         useEffect(() => {
          setPeriodicAmountError("")
         }, [periodicAmount,statetable]);
        //   const validateStartDate = (value) => {
        // const today = new Date();
        // today.setHours(0, 0, 0, 0);

        // const startDate = new Date(value);

        // return startDate >= today;}
       
    return(
<div className="total-investment">
  <div className='investment-full-box'>
    <div className='investment-label-box'>
      Investments
      <div className='add-button'>
        <button className='investment-add-button' onClick={()=>{setStatetable(true)}} active={statetable===true}>Add <FaPlus/></button>
      </div> 
    </div>

<div className='investment-table-box'>
        <Table responsive borderless className='add-investment-table'>
                <tr>
                    <th className='table-heading'>Name</th>
                    <th  className='table-heading'>Start Date</th>
                    <th className='table-heading'>End Date</th>
                    <th className='table-heading'>Due Date</th> 
                    <th className='table-heading'>Frequency</th>
                    <th className='table-heading'>Status</th>
                    <th className='table-heading'>Amount</th>
                    <th className='table-heading'>Periodic<br/>Amount</th>
                </tr>
                <tbody>
          {(investment != null && investment.length!=0) ?(investment.map((expense, index) => (
            <tr key={index}>
              <td className='table-data' style={{width:'150px'}} >
              {expense.isEditing ? (
                <input
                  type="text"
                  className='input'
                  style={{width:'120px',padding:'0px'}}
                  Value={expense.name}
                  onChange={(e) => {setUpdatedExpense({...updatedExpense,name: e.target.value }); }}
                />
              ) : (
                <div>{expense.name}</div>
              )}
                 

              </td>
              <td className='table-data'>
              {expense.isEditing ? (
                    <DatePicker
                    showIcon
                    className='input-tab-investment'
                    selected={moment(expense.startdate).toDate()}
                    value={updatedExpense.startdate}
                    toggleCalendarOnIconClick
                    dateFormat="dd-MMM-yyyy"
                    onChange={(date) => {setUpdatedExpense({ ...updatedExpense, startdate: formatDateForExpense(date) });  }}
                    
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
        <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>} 
                  />
                ) : (
                  <div>{expense.startdate}</div>
                )}
              </td>
              <td className='table-data'>
              {expense.isEditing ? (
                <DatePicker
                
                showIcon
                className='input-tab-investment'
                toggleCalendarOnIconClick
                selected={moment(expense.enddate).toDate()}
                value={updatedExpense.enddate}
                dateFormat="dd-MMM-yyyy"
                onChange={(date) => {setUpdatedExpense({ ...updatedExpense, enddate: formatDateForExpense(date) }); }}
                required
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>} 
                />
                ) : (
                  <div>{expense.enddate}</div>
                )}
              </td >
              <td className='table-data'>
              {expense.isEditing ? (
                <DatePicker
                showIcon
                className='input-tab-investment'
                toggleCalendarOnIconClick
                selected={moment(expense.duedate).toDate()}
                value={updatedExpense.dueDate}

                dateFormat="dd-MMM-yyyy"
                onChange={(date) => {setUpdatedExpense({ ...updatedExpense, duedate: formatDateForExpense(date) }); }}
                required
                icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>} 
                />
                ) : (
                  <div>{expense.duedate}</div>
                )}
              </td >
              <td className='table-data' style={{maxWidth:'100px',minWidth:'100px'}}>
              {expense.isEditing ? (
                <select name="frequency" id="frequency" className='input' style={{width:'90px',padding:'0px'}} onChange={(e)=>setUpdatedExpense({ ...updatedExpense, frequency: e.target.value })} value={updatedExpense.frequency}>
                <option value="Yearly" selected={expense.frequency === "Yearly"}>Yearly</option>
                <option value="Half-Yearly" selected={expense.frequency === "Half-Yearly"}>Half-Yearly</option>
                <option value="Quaterly" selected={expense.frequency === "Quaterly"}>Quaterly</option>
                <option value="Monthly" selected={expense.frequency === "Monthly"}>Monthly</option>
              </select>
              
                   
                ) : (
                  <div>{expense.frequency}</div> 
                )}
                 

              </td>
              <td className='table-data' style={{maxWidth:'90px',minWidth:'90px'}} >
              {expense.isEditing ? (
                 <select name='status' id='status'  className='input' style={{width:'90px',padding:'0px'}} onChange={(e) => {setUpdatedExpense({ ...updatedExpense, status: e.target.value })}}>
                 <option value='Active' selected={expense.status==="Active"}>Active</option>
                 <option value='Inactive' selected={expense.status==="Inactive"}>Inactive</option>
               </select> 
                ) : (
                  <div>{expense.status}</div>
                )}
                

              </td>
              <td className='table-data'>
              {expense.isEditing ? (
                 <input className='Add-amount' type="number" style={{width:'120px',padding:'0px'}} name="amount" defaultValue={expense.amount} onChange={(e) => {setUpdatedExpense({ ...updatedExpense,amount: e.target.value }); }}/>
                ) : (
                  <div>{expense.amount}</div>
                )}
              </td>
              <td className='table-data'>
              {expense.isEditing ? (
                 <input className='Add-amount' type="number" style={{width:'120px',padding:'0px'}} name="amount" defaultValue={expense.periodic_amount} onChange={(e) => {setUpdatedExpense({ ...updatedExpense,periodic_amount: e.target.value }); }}/>
                ) : (
                  <div>{expense.periodic_amount}</div>
                )}
              </td>
              <td  style={{width:'30px',maxWidth:'30px'}}>
              {expense.isEditing ? (
                  <div  onClick={() => handlePlus(index)}><FaPlus/></div>
               ) : (
                  <img src='edit-3.svg'  onClick={()=>handleEditSave(index)}></img>
                  )}
              </td>
              <td  style={{width:'30px',maxWidth:'30px'}}>
              {expense.isEditing ? (
                 <RxCross2 onClick={()=> handleCloseEdit(index)}/>
               ) : (
                 <img  src='trash-2.svg' onClick={()=>{handledeleteinvest(index)}}></img>
                  )}
              </td>
          </tr>
          ))):(<td>There is no investment add one</td>)}                   
          </tbody>
        </Table>
        </div>
        <button className='save-button' onClick= {handleOnSubmit}>Submit</button>
        {statetable===true ?     
         <form className='add-invest-pop' >
            <Modal isOpen={statetable} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              background:'white',
              width:'420px',
              height:'680px',
              padding:' 40px 30px',
              overflow:'none',
              borderRadius: '10px',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
            },}}> 
                         
              <div className="label-of-add-invest-popup">
                <label>Add Investment</label>
                <RxCross2 className='close-add-investment' onClick={handlePopUPClose} />
              </div>
              <br /> 
              <div style={{marginTop:'20px'}}>
                <input placeholder='Name' className='name-popup'  onChange={(e)=>{setInvestFormdata(e.target.value)}} />
                <span className="" style={{fontSize:'small',color:'red',position:'absolute'}}>{investmentNameError}</span>
              </div>
              <div className='date-in-popup' style={{marginTop:'20px'}}>
                <DatePicker
                    placeholderText='Start Date'
                    showIcon
                    id='startdate-popup'
                    selected={startDateInvest}
                    onChange={(date) => setStartDateInvest(date)}
                    dateFormat="dd-MM-yyyy"
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    toggleCalendarOnIconClick
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                  />
                <DatePicker
                    placeholderText='End Date'
                    showIcon
                    selected={endDateInvest}
                    onChange={(date) => setEndDateInvest(date)}
                    id='startdate-popup'
                    dateFormat="dd-MM-yyyy"
                    toggleCalendarOnIconClick
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                  />
               
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between",position:'absolute' }}>
                <span className="" style={{fontSize:'small',color:'red',paddingRight:'110px'}}>{startDateError}</span>
                <span className="" style={{fontSize:'small',color:'red'}}>{endDateError}</span>
              </div>
              
              <div style={{marginTop:'20px'}}>
                <DatePicker
                    placeholderText='Due Date'
                    showIcon
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    toggleCalendarOnIconClick
                    id='startdate-popup-for-duedate'
                    style={{width:'360px'}}
                    dateFormat="dd-MM-yyyy"
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                />
              </div> 
              <span className="" style={{fontSize:'small',color:'red',position:'absolute'}}>{dueDateError}</span>
              <div className='frequency-div'style={{marginTop:'20px'}}>
                <label className='label-in-popup-in-investment' >Frequency</label>
                <select name="frequency" id="frequency" className='input' onChange={(e)=>setFrequency(e.target.value)}>
                  <option value="Yearly" >Yearly</option>
                  <option value="Half Yearly">Half-Yearly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
              
              <div className='status-div' style={{marginTop:'20px'}}>
                <label className='label-in-popup-in-investment'>Status</label>
                <select name='status' id='status'  className='input' onChange={(e)=> setStatus(e.target.value)}>
                  <option value='Active' >Active</option>
                  <option value='Inactive'>Inactive</option>
                </select>
              </div>
 
              <div style={{paddingTop:'20px'}}>
                <input  placeholder='Amount' type='number' className='Add-amount-in-popup' style={{marginRight:'25px'}} onChange={(e)=> setAmountFormdata(parseInt(e.target.value))} />
                <input  placeholder='Periodic Amount' type='number' className='Add-amount-in-popup' onChange={(e)=> setPeriodicAmount(parseInt(e.target.value))} />
              </div>
              <div style={{ display: 'flex', justifyContent: "space-between",position:'absolute' }}>
                <span className="" style={{fontSize:'small',color:'red',paddingRight:'110px'}}>{investmentAmountError}</span>
                <span className="" style={{fontSize:'small',color:'red'}}>{periodicAmountError}</span>
              </div>
              <div className='submit-in-popup'>
                  <button className='submit-button-for-investment' onClick={handleSave} >Save</button>
              </div>
          </Modal>  
         </form>
         :null}
</div>
<ToastContainer
position="bottom-right"
autoClose={1000}
limit={3}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
</div>


    );
        }
export default InvestmentPage;
