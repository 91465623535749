import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import './side_nav.css';
import React from 'react';
import { LuLayoutDashboard } from "react-icons/lu";
import { useLocation } from 'react-router-dom';


function PillsExample() {
  const location = useLocation();
  return (<div className='left-nav'>
    
    <Nav defaultActiveKey="/home" variant="pills" className="flex-column">

        <Nav.Item className='title-budget' href="/home">
        
        <div> <Image src='budgeting (2) 1app-symbol.png' style={{paddingRight:'20px',marginLeft:'70px'}}/><br/>365 SmartBudget</div></Nav.Item>
        
      <Nav.Item >
        <Nav.Link className='nav-link-side' href="#/home" active={location.pathname === "/home" || location.pathname === "/"}><Image src='fluent-emoji-high-contrast_money-baghome-side-nav.png' style={{paddingRight:'20px'}} />Home</Nav.Link>
      </Nav.Item>
      <Nav.Item >
        <Nav.Link className='nav-link-side'  eventKey="link-1" href="#/budgetplanning" active={location.pathname === "/budgetplanning"} ><Image src="dollar-sign.png" style={{paddingRight:'20px'}} />Budget Planning</Nav.Link>
      </Nav.Item>
      <Nav.Item >
        <Nav.Link className='nav-link-side' eventKey="link-2" href='#/investment' active={location.pathname === "/investment"} ><Image src='dollar-signinvestment-side-icon.png' style={{paddingRight:'20px'}} />Investment</Nav.Link>
      </Nav.Item>
      <Nav.Item >
        <Nav.Link className='nav-link-side' eventKey="link-3" href='#/reminder' active={location.pathname === "/reminder"}><Image src='dollar-signreminder-side-nav.png' style={{paddingRight:'20px'}}/>Reminder</Nav.Link>
      </Nav.Item>
      <Nav.Item >
        <Nav.Link className='nav-link-side' eventKey="link-4" href='#/dashboard' active={location.pathname === "/dashboard"}><Image src='pie-chartdashboard-side-nav.png' style={{paddingRight:'20px'}}/>Dashboard</Nav.Link>
      </Nav.Item>
    </Nav>

    </div>
  );
}

export default PillsExample;
