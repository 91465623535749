import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const DropdownMonth = (props) => {
 const [selectedMonthYear, setSelectedMonthYear] = useState('');
 const monthArray = [];

 for (let i = 0; i < 6; i++) {
  let monthDate = new Date();
  monthDate.setDate(1); // Set to the first of the month to avoid issues with day overflow
  monthDate.setMonth(monthDate.getMonth() - i - 1);
  let month = monthDate.toLocaleString('default', { month: 'short' });
  let year = monthDate.getFullYear();
  let longMonth = monthDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  monthArray.push({ long: longMonth, short: `${month} ${year}` });
}


 const handleMonthYearChange = (e) => {
    setSelectedMonthYear(e.target.value);
 };

 return (
    <div>
      
      <select id="months" value={props.selectedMonthYear} onChange={props.onChange}>
        {monthArray.map((monthYear, index) => (
          <option key={index} value={monthYear.long}>
            {monthYear.short}
          </option>
        ))}
      </select>
      
      <style JSX>{`  #months{    
      display: flex;
      width: 150px;
      padding: 15px 20px;
      justify-content: center;
      align-items: center;
      border:none;
      border-radius: 10px;
      background: var(--Off-White, #F5F5F5);
        }`}

      </style>
    </div>
 );
};

export default DropdownMonth;