import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';
import Dropdown from 'react-multilevel-dropdown';
import './dashboard.css';
import { ToastContainer, toast } from 'react-toastify';

import {
  calculateTotalAmount,
  getIncome,
  getSavingInBudget,
  getMonthData,
  getAllMonthsTillCurrentMonth,
  ifNaN,
  getCurrentMonth,
  getInvestment,
} from '../../components/helper/get_formated_date';

export default function DashboardChart(props) {
  const { Chart1, Chart2, Chart3, Chart4, Chart5, selectedMonths, setSelectedMonths } = props;

  const token = localStorage.getItem('user');
  const [selectedExpense, setSelectedExpense] = useState('Rent');
  const [MonthDetails, setMonthDetails] = useState('ThisMonth');
  const [saving, setSaving] = useState([]);
  const [budget, setBudget] = useState([]);
  const [invest, setInvest] = useState([]);
  const [reminder, setReminder] = useState([]);
  const [checkedMonths, setCheckedMonths] = useState([]);
  const default_expense_string = localStorage.getItem('default_expense');
  const default_expense = JSON.parse(default_expense_string);
  const [sixMonthsChecked, setSixMonthsChecked] = useState(false);
  const [monthChecks, setMonthChecks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'post',
          url: 'http://68.178.172.104:6010/api/dashboard',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.request(config);
        if (!response.data ) {
          toast.error("Something Went Wrong!");
        } else {
          setSaving(response.data.savings);
          setBudget(response.data.budget);
          setInvest(response.data.investment);
          setReminder(response.data.reminder);
        }
      } catch (error) {
        toast.error("Something Went Wrong!");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedMonths.length === 0) {
      setMonthDetails('ThisMonth');
    } else {
      setMonthDetails(null);
    }
  }, [selectedMonths]);

  function PastSixMonth() {
    const monthOfSix = [];
    for (let i = 0; i < 6; i++) {
      const monthArray = [];
      let monthDate = new Date();
      monthDate.setMonth(monthDate.getMonth() - i);
      let month = monthDate.toLocaleString('default', { month: 'short' });
      let year = monthDate.getFullYear();
      monthOfSix.push([month, year]);
    }

    return monthOfSix;
  }

  const monthArray = PastSixMonth();

  const toggleMonth = (index) => {
    const selectedIndex = selectedMonths.indexOf(index);
    if (selectedIndex === -1) {
      setSelectedMonths([...selectedMonths, index]);
    } else {
      const updatedSelectedMonths = [...selectedMonths];
      updatedSelectedMonths.splice(selectedIndex, 1);
      setSelectedMonths(updatedSelectedMonths);
    }
  };

  const handleMonthCheck = (index) => {
    const updatedMonthChecks = [...monthChecks];
    updatedMonthChecks[index] = !updatedMonthChecks[index];
    setMonthChecks(updatedMonthChecks);
  };

  function AllMonthData(MonthDetails, monthYear) {
    if (MonthDetails === 'ThisMonth') {
      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
      const currentYear = currentDate.getFullYear();
      return [[currentMonth, currentYear]];
    } 
    else if (monthYear.length === 0) {
      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
      const currentYear = currentDate.getFullYear();
      return [[currentMonth, currentYear]];
    }
    else if (monthYear.length !== 0 && MonthDetails == null) {
      const newArray = monthYear.map((index) => monthArray[index]);
      const shortToLongMonthNames = {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December',
      };

      for (let i = 0; i < newArray.length; i++) {
        const shortMonth = newArray[i][0];
        if (shortToLongMonthNames.hasOwnProperty(shortMonth)) {
          newArray[i][0] = shortToLongMonthNames[shortMonth];
        }
      }
      return newArray;
    }
  }

  function ChartOneData() {
    const currentMonthYear = getCurrentMonth();
    let incomeExpense = [['Month', 'Income', 'Expense', 'Investment']];
    const monthData = getMonthData(budget, currentMonthYear[0], currentMonthYear[1]);
    const income = ifNaN(getIncome(monthData));
    const totalAmount = ifNaN(calculateTotalAmount(monthData));
    const investmentForCurrentMonth = ifNaN(getInvestment(monthData));
    incomeExpense.push([currentMonthYear[0], income, totalAmount, investmentForCurrentMonth]);
    return incomeExpense;
  }

  const customMonth = AllMonthData(MonthDetails, selectedMonths);

  function ChartTwoData() {
    let savingChart = [['Month', 'Savings']];
    if (MonthDetails === 'ThisMonth') {
      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
      const currentYear = currentDate.getFullYear();
      const currentMonthData = getMonthData(budget, currentMonth, currentYear);
      const totalAmount = ifNaN(calculateTotalAmount(currentMonthData));
      const income = ifNaN(getIncome(currentMonthData));
      const BudgetSaving = ifNaN(parseInt(getSavingInBudget(currentMonthData)));
      const currentMonthSavings = income - totalAmount + BudgetSaving;
      savingChart.push([currentMonth, currentMonthSavings]);
    } else {
      const customMonth = AllMonthData(MonthDetails,selectedMonths);
      customMonth.forEach((MonthYear) => {
        const monthData = getMonthData(budget, MonthYear[0], MonthYear[1]);
        const totalAmount = ifNaN(calculateTotalAmount(monthData));
        const income = ifNaN(getIncome(monthData));
        const BudgetSaving = ifNaN(parseInt(getSavingInBudget(monthData)));
        const currentMonthSavings = income - totalAmount + BudgetSaving;
        savingChart.push([MonthYear[0], currentMonthSavings]);
      });
    }
    return savingChart;
  }

  function ChartThreeData() {
    let expenseChart = [['Month', 'Expense']];
    if (MonthDetails === 'ThisMonth') {
      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
      const currentYear = currentDate.getFullYear();
      const currentMonthData = getMonthData(budget, currentMonth, currentYear);
      const totalAmount = ifNaN(calculateTotalAmount(currentMonthData));
      const currentMonthExpenses = totalAmount;
      expenseChart.push([currentMonth, currentMonthExpenses]);
    } else {
      const customMonth = AllMonthData(MonthDetails, selectedMonths);
      customMonth.forEach((MonthYear) => {
        const monthData = getMonthData(budget, MonthYear[0], MonthYear[1]);
        const totalAmount = ifNaN(calculateTotalAmount(monthData));
        const currentMonthExpenses = totalAmount;
        expenseChart.push([MonthYear[0], currentMonthExpenses]);
      });
    }
    return expenseChart;
  }

  function ChartFourData() {
    let investmentPlan = [['Month', 'Investment']];

    if (MonthDetails === 'ThisMonth') {
      const currentMonthYears = getCurrentMonth();
      const monthDatas = getMonthData(budget, currentMonthYears[0], currentMonthYears[1]);
      const totalInvestment = ifNaN(getInvestment(monthDatas));
      investmentPlan.push([currentMonthYears[0], totalInvestment]);
    } else {
      const customMonth = AllMonthData(MonthDetails, selectedMonths);
      customMonth.forEach((MonthYear) => {
        const monthDatas = getMonthData(budget, MonthYear[0], MonthYear[1]);
        const totalInvestment = ifNaN(getInvestment(monthDatas));
        investmentPlan.push([MonthYear[0], totalInvestment]);
      });
    }

    return investmentPlan;
  }

  function ChartFiveData() {
    let default_expense_chart = [['Month', 'Amount']];
    const allMonthsTillCurrentMonth = getAllMonthsTillCurrentMonth();
    allMonthsTillCurrentMonth.forEach((MonthYear) => {
      let monthTotal = 0;
      for (let j = 0; j < budget.length; j++) {
        const expenses = budget[j][2];
        for (let i = 0; i < expenses.length; i++) {
          const expenseDate = new Date(expenses[i].date);
          const expenseMonth = expenseDate.toLocaleString('en-US', { month: 'long' });
          const expenseYear = expenseDate.getFullYear();

          if (expenseMonth === MonthYear[0] && expenseYear === MonthYear[1] && expenses[i].name === selectedExpense) {
            monthTotal += expenses[i].amount;
          }
        }
      }
      default_expense_chart.push([MonthYear[0], monthTotal]);
    });
    return default_expense_chart;
  }

  return (
    <div className='chart-container'>
      <>
        {Chart1 ? (
          <div className='column'>
            <Chart
              chartType='BarChart'
              options={{ title: 'Current Month Insight', titleTextStyle: { fontSize: 20 } }}
              data={ChartOneData()}
              width='100%'
              height='400px'
              legendToggle
            />
          </div>
        ) : null}
      </>
      <>
        {Chart2 ? (
          <div className='column'>
            <Chart
              chartType='LineChart'
              data={ChartTwoData()}
              options={{ title: 'Monthly Savings Trends', titleTextStyle: { fontSize: 20 } }}
              width='100%'
              height='400px'
              legendToggle
            />
          </div>
        ) : null}
      </>
      <>
        {Chart3 ? (
          <div className='column'>
            <Chart
              chartType='LineChart'
              data={ChartThreeData()}
              options={{ title: 'Monthly Expense Trends', titleTextStyle: { fontSize: 20 } }}
              width='100%'
              height='400px'
              legendToggle
            />
          </div>
        ) : null}
      </>
      <>
        {Chart4 ? (
          <div className='column'>
            <Chart
              chartType='BarChart'
              data={ChartFourData()}
              options={{ title: 'Monthly Investment Trends', titleTextStyle: { fontSize: 20 } }}
              width='100%'
              height='400px'
              legendToggle
            />
          </div>
        ) : null}
      </>
      <>
        {Chart5 ? (
          <div className='column'>
            <select
              style={{
                width: '121px',
                height: '40px',
                background: '#30B664',
                color: 'white',
                border: 'none',
                alignItems: 'center',
                border:'none',
                fontWeight:'bold',
                borderRadius: '4px',
              }}
              value={selectedExpense}
              onChange={(e) => setSelectedExpense(e.target.value)}
            >
              {default_expense &&
                default_expense.map((expense, index) => (
                  <option
                    key={index}
                    value={expense.expense}
                    style={{
                      border: 'none',
                      borderRadius:'4px',
                      background: 'none',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      color: 'black',
                      fontSize:'14px'
                    }}
                  >
                    {expense.expense}
                  </option>
                ))}
            </select>

            <Chart
              chartType='BarChart'
              data={ChartFiveData()}
              options={{ title: 'Default Expenses', titleTextStyle: { fontSize: 20 } }}
              width='100%'
              height='400px'
              legendToggle
            />
          </div>
        ) : null}
      </>
      <ToastContainer
position="bottom-right"
autoClose={1000}
limit={3}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
    </div>
  );
}
