import { RouterProvider, createHashRouter } from "react-router-dom";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Budget from "./views/BudgetPage";
import Mainpage from './views/Mainpage';
import Forgetpassword from "./views/ForgetPassword";
import Reset from "./views/Reset";
import Reminder from "./views/Reminder";
import Investment from "./views/Investment";
import Dashboard from './views/Dashboard';

const router = createHashRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "/signup",
        element: <Signup />,
    },
    {
        path: "/budgetplanning",
        element: <Budget />,
    },
    {
        path:'/home',
        element:<Mainpage/>,
    },
    {
        path:'/reset',
        element:<Reset/>,
    },
    {
        path:'/forgetpass',
        element:<Forgetpassword/>,
    },
    {
        path:'/reminder',
        element:<Reminder/>
    },
    {
        path:'/investment',
        element:<Investment/>

    },{
        path:"/dashboard",
        element:<Dashboard/>
    }
])
function App() {
    return <RouterProvider router={router}/>;
}

export default App;