import DashboardTab from '../pages/dashboard/dashboard';
import { Col, Row } from "react-bootstrap";
import Sidenav from '../components/side_nav';
import Topnav from '../components/top_nav';
import Container from 'react-bootstrap/Container';

function DashBoard() {
    return ( 
        <>
    <Container fluid>
        <Row>
            <Col md="auto"><Sidenav/></Col>
            <Col className='top-nav-bar'>
                <Topnav/>
                <DashboardTab/>
            </Col>
        </Row>
    </Container>
        </>
     );
}

export default DashBoard;