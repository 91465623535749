import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './signup.css';
import states, { India_state } from '../components/states';
import { countries } from '../components/countries'; // Import as array
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Image from 'react-bootstrap/Image';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

function Signup() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobnum: '',
    userid: '',
    password: '',
    cpassword: '',
    gender: '',
    marital: '',
    occupation: '',
    age: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
  });

  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [mobnum, setMobnum] = useState('');
  const [userid, setUserid] = useState('');
  const [message, setMessage] = useState(''); // Added state for message
  const navigate = useNavigate();

  // Convert countries array into the format required by react-select
  // const countryOptions = country.map(country => ({ value: country, label: country }));
  // const country=countries()

  
  // Update state options based on selected country
  // const stateOptions = formData.country === 'India' 
  //   ? India_state.map(state => ({ value: state, label: state })) 
  //   : states.map(state => ({ value: state, label: state }));

  // const onChangeHandler = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption.value,
      state: '' // Reset state if country changes
    });
  };

  const handleStateChange = (selectedOption) => {
    setFormData({
      ...formData,
      state: selectedOption.value
    });
  };
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      width: '374px',
      height: '44px',
      border: '1px solid #ced4da',
      borderRadius: '10px',
      fontSize: '14px',
      padding: '2px 8px',
      backgroundColor: '#f5f5f5',
    }),
    placeholder: (provided) => ({
      ...provided,
      // color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#495057',
    }),
    menu: (provided) => ({
      ...provided,
      width: '374px',
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#e9ecef' : '#fff',
      color: '#000',
      ':active': {
        backgroundColor: '#f5f5f5',
      },
    }),
  };
  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: 'none', // Remove border
  //     boxShadow: 'none',
  //     backgroundColor: state.isDisabled ? 'white' : provided.backgroundColor,
  //     width:170, // Remove box shadow
  //   }),
  // };
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    let newFormError = { ...formError };

    // Clear the error message for the specific input field
    if (newFormError[name]) {
      delete newFormError[name];
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormError(newFormError);
  };
const validateForm = () => {
let err = {}
if (formData.firstname === '') {
  err.firstname = 'FirstName required!'
  
}
if (formData.lastname === '') {
    err.lastname = 'Lastname required!'

  }
if (formData.email === '') {
  err.email = 'Email required!'

} else {
  let mail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (!mail.test(formData.email)) {
    err.email = 'Email not valid!' 
  }
}
if (formData.password === '' || formData.cpassword === '') {
  err.password = 'Password and Confirm Password required!'
  
} else {
  if (formData.password !== formData.cpassword) {
    err.password = 'Password not matched!'
    
  } else {
    if (formData.password.length < 8) {
      err.password = 'Password must be at least 8 charecters long.'
      
    }
    let countUpperCase = 0
let countLowerCase = 0
let countDigit = 0
let countSpecialCharacters = 0

for (let i = 0; i < formData.password.length; i++) {
  const specialChars = ['!','@','#','$','%','^','&','*','(',')','_','-','+','=','[','{',']','}',':',';','<','>',]
  if (specialChars.includes(formData.password[i])) {
    countSpecialCharacters++
  } else if (!isNaN(formData.password[i] * 1)) {
    countDigit++
  } else {
    if (formData.password[i] === formData.password[i].toUpperCase()) {
      countUpperCase++
    }
    if (formData.password[i] === formData.password[i].toLowerCase()) {
      countLowerCase++
    }
  }
}

if (countLowerCase === 0) {
  err.password ='Password must include at least one lowercase letter.'
  
}

if (countUpperCase === 0) {
  err.password ='Password must include at least one uppercase letter.'
  
}

if (countDigit === 0) {
  err.password ='Password must include at least one digit.'
  
}

if (countSpecialCharacters === 0) {
  err.password ='Password must include at least one special character (@#$%^&+=*).'
}
  }
}
if(mobnum===''){
  err.mobnum="Mobile number should not be empty"
  
}
if(userid===""){
  err.userid="User id should not be empty"
}
  if (mobnum.length < 10) {
    err.mobnum = 'Mobile number should have 10 digits'
    
  }
  if (formData.gender === '') {
    err.gender = 'Gender required!'
    
  }
  
  if (formData.marital === '') {
    err.marital = 'Marital Status required!'
    
  }
     if (formData.occupation === '') {
  err.occupation = 'Occupation required!'
  
}
if (formData.age.length === ''||formData.age<13||formData.age>120) {
    err.age = 'Age required!'
    
  }
  if (formData.country === '') {
    err.country = 'Country required!'
    
  }
if ((formData.country==="India" || formData.country === "United States") && formData.state === '') {
    err.state = 'State required!'
    
  }
  if (formData.city === '') {
    err.city = 'City required!'
    
  }
  if (formData.zipcode === '') {
    err.zipcode = 'Zipcode required!'
    
  }
  if(formData.zipcode.length<6||formData.zipcode.length>6){
    err.zipcode="zipcode should be 6 number"
  }
setFormError({ ...err })

return Object.keys(err).length < 1;
}

  const onSubmitHandler = async (e) => {
    e.preventDefault();
     validateForm()
     if(validateForm()=== false){
      return
     }else{
    
    try {
      console.log(formData.state)
      console.log(formData)
      //let data = new FormData();
      const data=new FormData()
      data.append('firstname',formData.firstname);
      data.append('lastname',formData.lastname);
     //  data.append('password',formData.password);
      data.append('cpassword',formData.cpassword);
      data.append('email',formData.email);
      data.append('userid',userid);
      data.append('mobnum',mobnum);
      data.append('gender',formData.gender);
      data.append('marital',formData.marital);
      data.append('occupation',formData.occupation);
      data.append('age',formData.age);
      data.append('country',formData.country);
      data.append('state',formData.state);
      data.append('city',formData.city);
      data.append('zipcode',formData.zipcode);
      
      const config = {
        method: 'post',
        url: 'http://68.178.172.104:6010/api/signup',
        data: data,
      };
      const response = await axios.request(config)
      console.log(response.data)
      //const response = await axios.post('http://127.0.0.1:5010/api/signup', data);
      if(response.data.status==="success"){
      toast.success(response.data.message);
      setMessage('Signup successful! Redirecting to login...');
      setTimeout(() => navigate('/login'), 2000);
      }
      else{
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Signup failed. Please try again.');
    }
  }};

  const loginpage = () => {
    navigate('/login');
  };

  return (
    <div className="total-login1">
      <div className='login-form'>
        <h1 className="app-names"><Image src='budgeting (2) 1app-symbol.png' /> 365 SmartBudget</h1>
        <form className="signup-form" method='POST'>
          <div className="form-group" style={{ display: 'flex', justifyContent: "space-between" }}>
            <input id='firstname' className="input" name="firstname" type='text' placeholder='Firstname*' onChange={onChangeHandler} value={formData.firstname} />
            <input id='lastname' className="input" name="lastname" type='text' placeholder='Lastname*' onChange={onChangeHandler} value={formData.lastname} />
          </div>
          <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div id='firstname' className='non-valid'>{formError.firstname}</div>
            <div id='lastname' className='non-valid'>{formError.lastname}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="email" type='email' placeholder='Email*' onChange={onChangeHandler} value={formData.email} />
            <div className='non-valid'>{formError.email}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="mobnum" type='number' placeholder='Mobile Number*' onChange={(e) => { onChangeHandler(e); setMobnum(e.target.value); }} value={mobnum} />
            <div className='non-valid'>{formError.mobnum}</div>
          </div>

          <div className="form-group">
            <label>Select User-Id: </label>
            <div className="checkbox-green-signup" style={{ display: 'flex', justifyContent: "space-between" }}>
              <input type="radio" id="c1-13" name="userid" onChange={(e) => setUserid(e.target.value)} value={formData.email} checked={userid === formData.email} />E-mail
              <input type="radio" id="c1-13" name="userid" onChange={(e) => setUserid(e.target.value)} value={mobnum} checked={userid === mobnum} />Mobile Number
            </div>
            <div className='non-valid'>{formError.userid}</div>
          </div>

          <div className="form-group">
            <div style={{ position: 'relative', width: '100%' }}>
              <input
                className="input"
                style={{ width: '100%', paddingRight: '30px' }}
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password*"
                onChange={onChangeHandler}
                value={formData.password}
              />
              <i
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer', color: 'blue'
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </i>
            </div>
            <div className='non-valid'>{formError.password}</div>
          </div>

          <div className="form-group">
            <div style={{ position: 'relative', width: '100%' }}>
              <input
                className="input"
                style={{ width: '100%', paddingRight: '30px' }}
                name="cpassword"
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password*"
                onChange={onChangeHandler}
                value={formData.cpassword}
              />
              <i
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer', color: 'blue'
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </i>
            </div>
          </div>

          <div className="form-group">
            <select className="input" style={{ width: '374px' }} name="gender" type='text' onChange={onChangeHandler} value={formData.gender}>
              <option value="">-Select Gender-</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="N/A">Prefer not to say</option>
            </select>
            <div className='non-valid'>{formError.gender}</div>
          </div>

          <div className="form-group">
            <select className="input" style={{ width: '374px' }} name="marital" type='text' onChange={onChangeHandler} value={formData.marital}>
              <option value="">-Select Marital Status-</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Others">Others</option>
             
            </select>
            <div className='non-valid'>{formError.marital}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="occupation" type='text' placeholder='Occupation*' onChange={onChangeHandler} value={formData.occupation} />
            <div className='non-valid'>{formError.occupation}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="age" type='number' max='120' min='13' placeholder='Age*' onChange={onChangeHandler} value={formData.age} />
            <div className='non-valid'>{formError.age}</div>
          </div>
          <div className="form-group">
          <Select
      options={countries()}
      onChange={handleCountryChange}
      value={
        formData.country
          ? { value: formData.country, label: formData.country }
          : null
      }
      className="placeholder_country"
      placeholder="-Select Country-"
      styles={customSelectStyles}
    />
            <div className='non-valid'>{formError.country}</div>
          </div>

          <div className="form-group">
            <select className="input" style={{ width: '374px' }} name="state" type='text' placeholder='State*' onChange={onChangeHandler} value={formData.state} disabled={formData.country !== 'United States' && formData.country !== 'India'}>
              <option value="">-Select State-</option>
              {formData.country === 'United States' && <>{states()}</>}
              {formData.country === 'India' && <>{India_state()}</>}
            </select>
            <div className='non-valid'>{formError.state}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="city" type='text' placeholder='City*' onChange={onChangeHandler} value={formData.city} />
            <div className='non-valid'>{formError.city}</div>
          </div>

          <div className="form-group">
            <input className="input" style={{ width: '374px' }} name="zipcode" type='number' placeholder='Zip-Code/pincode*' onChange={onChangeHandler} value={formData.zipcode} />
            <div className='non-valid'>{formError.zipcode}</div>
          </div>

          <div className="form-group">
            <button className="button-29" onClick={onSubmitHandler}>Signup</button>
          </div>
          <div>{message}</div>
          <div className="already-have-a-account">Already have an account?</div>
          <button className="button-29" onClick={loginpage}>Login</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Signup;