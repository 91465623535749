import React, {useState,useEffect} from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';
import DashboardChart from './dashboardCharts';
import Dropdown from 'react-multilevel-dropdown';
import './dashboard.css';


const Dashboard = () => {
  const [chart1, setChart1] = useState(true);
  const [chart2, setChart2] = useState(true);
  const [chart3, setChart3] = useState(true);
  const [chart4, setChart4] = useState(true);
  const [chart5, setChart5] = useState(true);
const [selectedMonths,setSelectedMonths]=useState([]);
 useEffect(() => {  
 }, [chart1,chart2,chart3,chart4,chart5]);

 function PastSixMonth() {
  const monthOfSix = [];
  for (let i = 0; i < 6; i++) {
      const monthArray = [];
      let monthDate = new Date();
      monthDate.setMonth(monthDate.getMonth() - i);
      let month = monthDate.toLocaleString('default', { month: 'short' });
      let year = monthDate.getFullYear();
      monthOfSix.push([month, year]);
  }
  return monthOfSix;
}

const monthArray = PastSixMonth();
 
 const toggleMonth = (index) => {
  const selectedIndex = selectedMonths.indexOf(index);
  if (selectedIndex === -1) {
      setSelectedMonths([...selectedMonths, index]);
  } else {
      const updatedSelectedMonths = [...selectedMonths];
      updatedSelectedMonths.splice(selectedIndex, 1);
      setSelectedMonths(updatedSelectedMonths);
  }
};
const toggleAllMonths = () => {
  if (selectedMonths.length === monthArray.length) {
      setSelectedMonths([]);
  } else {
      const allMonthsIndexes = monthArray.map((_, index) => index);
      setSelectedMonths(allMonthsIndexes);
  }
};


  return (
    <div style={{maxHeight:"600px",overflow:"auto",padding:"30px",background:'#f5f5f5'}}>
      <div style={{display:"flex",justifyContent:"space-between",marginBottom:'30px'}}>
      <div style={{fontSize:'32px',color: 'black',fontWeight: '600'}}>Dashboard</div>
          <div> 
            <Dropdown title='Select Months' style={{ background: "#30B664", color: "white", fontSize: "16px" }}>
                        <Dropdown.Item onClick={toggleAllMonths}>
                            6 Months
                        </Dropdown.Item>
                        {monthArray.map((monthYear, index) => (
                            <Dropdown.Item key={index}>
                                <input
                                    type="checkbox"
                                    id={`month_${index}`}
                                    checked={selectedMonths.includes(index)}
                                    onChange={() => toggleMonth(index)}
                                />
                                <label htmlFor={`month_${index}`}>{monthYear[0]} {monthYear[1]}</label>
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                    </div>

      <div style={{display:'inline-block',float:'right'}}>
        
      <button
    className="btn btn-success dropdown-toggle"
    type="button"
    id="multiSelectDropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    style={{ float: 'right', marginRight: '10px', background: "#30B664", border: 'none', fontWeight: 'bold' }}
>
    Select Charts
</button>
<ul className="dropdown-menu dropdown-menu-start"
    aria-labelledby="multiSelectDropdown" style={{ fontSize: "14px", position: "absolute", border: 'none' }}>
    <li style={{ marginBottom: "10px", paddingLeft: "10px" }}> 
        <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox"
                value={chart1}
                checked={chart1 ? true : false}
                onChange={() => setChart1(!chart1)} />
            <span style={{ marginLeft: '5px' }}>Current Month Insight</span>
        </label>
    </li>
    <li style={{ marginBottom: "10px", paddingLeft: "10px" }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox"
                value={chart2}
                checked={chart2 ? true : false}
                onChange={() => setChart2(!chart2)} />
            <span style={{ marginLeft: '5px' }}>Savings</span>
        </label>
    </li>
    <li style={{ marginBottom: "10px", paddingLeft: "10px" }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox"
                value={chart3}
                checked={chart3 ? true : false}
                onChange={() => setChart3(!chart3)} />
            <span style={{ marginLeft: '5px' }}>Expense</span>
        </label>
    </li>
    <li style={{ marginBottom: "10px", paddingLeft: "10px" }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox"
                value={chart4}
                checked={chart4 ? true : false}
                onChange={() => setChart4(!chart4)} />
            <span style={{ marginLeft: '5px' }}>Investment</span>
        </label>
    </li>
    <li style={{ marginBottom: "10px", paddingLeft: "10px" }}>
        <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox"
                value={chart5}
                checked={chart5 ? true : false}
                onChange={() => setChart5(!chart5)} />
            <span style={{ marginLeft: '5px' }}>Default Expenses</span>
        </label>
    </li>
</ul>

            </div>
          </div>
        <DashboardChart
        Chart1={chart1}
        Chart2={chart2}
        Chart3={chart3}
        Chart4={chart4}
        Chart5={chart5}
        selectedMonths={selectedMonths}
        setSelectedMonths={setSelectedMonths}
        // toggleAllMonths={toggleAllMonths}
        // toggleMonth={toggleMonth}
        />
    </div>
    
  );
};

export default Dashboard;