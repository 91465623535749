import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './login.css';
import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { ToastContainer, toast } from 'react-toastify';

function Login() {
  localStorage.removeItem('user');
  // Other localStorage removals...
  localStorage.removeItem('name');
  localStorage.removeItem('default_expense');
  localStorage.removeItem('email_verified');
  localStorage.removeItem('whatsapp_verified');
  localStorage.removeItem('whatsapp_notification');
  localStorage.removeItem('email_notification');
  localStorage.removeItem('email');
  localStorage.removeItem('default_reminder');
  localStorage.removeItem('whatsapp');
  localStorage.removeItem('full_name');
  localStorage.removeItem('occupation');
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  // const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const onSubmit = () => {
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError=false;
    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    if (username === '' ||username===null ||username===undefined) {
      setUsernameError('Please enter your UserID');
      hasError=true
    } 
    else if (!mailFormat.test(username)) {
      setUsernameError('EmailAddress/PhoneNumber is not valid.');
      hasError=true
    }
    if ( password === "" || password===null || password===undefined) {
      setPasswordError('Password should not be empty');
      hasError=true
    } 
    if(hasError){
      return
    }
    
    
    const data = new FormData();
    data.append('userid', username);
    data.append('password', password);
    const config = {
      method: 'post',
      url: 'http://68.178.172.104:6010/api/login',
      data: data,
    };
    try {
      const response = await axios.request(config);
      if (response.data.access_token) {
        localStorage.setItem('user', response.data.access_token);
        localStorage.setItem('name', response.data.name);
        localStorage.setItem('default_expense', JSON.stringify(response.data.default_expense));
        localStorage.setItem('email_verified', response.data.email_verified);
        localStorage.setItem('whatsapp_verified', response.data.whatsapp_verified);
        localStorage.setItem('whatsapp_notification', response.data.whatsapp_notification);
        localStorage.setItem('email_notification', response.data.email_notification);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('whatsapp', response.data.whatsapp);
        localStorage.setItem('full_name', response.data.full_name);
        localStorage.setItem('default_reminder', JSON.stringify(response.data.default_reminder));
        localStorage.setItem('occupation', response.data.occupation);
        navigate('/home');
      }
      if(response.data.status==="failure"){
        if(response.data.message==="Invalid User ID"){
          setUsernameError('User not found');
        }
        else if (response.data.message === 'Incorrect password') {
          setPasswordError('Incorrect Password');
        }
        
      }else{
        setPasswordError("User ID or password is invalid")
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
      // if (error.response) {
      //   console.log('Error response:', error.response.data);
      //   const message = error.response.data.message;
      //   if (message === 'incorrect password') {
      //     setPasswordError('Incorrect Password');
      //   } else if (message === 'Invalid User ID') {
      //     setUsernameError('User not found');
      //   } else {
      //     setUsernameError('User ID or password is invalid');
      //   }
      // } else {
     //}
    }
  };
  
  const forgetPassword = () => {
    navigate('/forgetpass');
  };

  const handleReset = () => {
    setUsername('');
    setPassword('');
  };

  const notRegistered = () => {
    navigate('/signup');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    setUsernameError("")
  }, [username]);
  useEffect(() => {
    setPasswordError("")
  }, [password]);
  return (
    <div className="total-login1">
      <div className="login-form1"> 
      <h1 className="app-name"><Image src='budgeting (2) 1app-symbol.png' />   365 SmartBudget</h1>

        {/* <p style={{color:'#30B664',fontSize:'26px',alignItems:'center',textAlign:'center',position:'relative',top:'35px',fontWeight:'bold'}}>365 SmartBudget</p> */}
        <form className="form-op1" method="post">
          <label className="label">
            User Id:
            <br />
            <input
              type="text"
              className="input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter User Id"
            />
          </label>
          <br />
          {/* <span className="error-style">{emailError}</span> */}
          <span className="error-style">{usernameError}</span>

          <br />
          <label className="label">
            Password:
            <br />
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                className="input password-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
              <i
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
                style={{ color: 'blue' }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </i>
            </div>
          </label>
          <br />
          <span className="error-style">{passwordError}</span>
          <br />
          <span id="forget-password1" onClick={forgetPassword}>
            Forget Password?
          </span>
          <br />
          {/* <span className="error-style">{usernameError}</span> */}
          <br />
          <div className="button-group-1">
            <button onClick={handleSubmit} className="save-button" style={{ width: '130px' }}>
              Login
            </button>
            <input
              type="reset"
              className="save-button"
              style={{ width: '130px' }}
              onClick={handleReset}
              value="Reset"
            />
          </div>
          <span className="label" id="not-reg" style={{margin:'10px'}}>
            Not Registered?          </span>

            <button id="signup-button" className="button-29" onClick={notRegistered}>
              Signup
            </button>
        </form>
        <ToastContainer />

      </div>

    </div>
  );
}

export default Login;
