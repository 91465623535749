import React from "react";
import axios from "axios";
import { useState,useEffect } from "react";
import Modal from 'react-modal';
import { RxCross2 } from "react-icons/rx";
import './verify_whatsapp.css';
import { Toaster } from 'react-hot-toast';
import { toast } from 'react-hot-toast';

function Verify_whatsapp(props) {
    const [whatsapp,setWhatsapp]=useState("");
    const token = localStorage.getItem('user');
    const [whatsappClose,setWhatsappClose]=useState(props.close)
    const [otp,setOtp]=useState("")
    const [otpState,setOtpState]=useState(false);
    const [otpMessage,setOtpMessage]= useState("")
    const [otpErrorMessage,setOtpErrorMessage]=useState("")
    const [expired, setExpired] = useState(false);
    const [errormessage,setErrorMessage]=useState("");

    useEffect(() => {
        if (otpMessage) {
          const timeoutId = setTimeout(() => {
            setOtpMessage(null);
            setOtpState(false);
            props.close();
          }, 5000); // 5000ms = 5 seconds
    
          // Clear the timeout if the component is unmounted or the message changes
          return () => clearTimeout(timeoutId);
        }
      }, [otpMessage]);

    const handlewhatsapp = async (e)=>{
        e.preventDefault();
        const data ={
            "data":{"mobile":whatsapp},
            "on":"mobile"
        }
  const config = {
         method: "post",
         url: "http://68.178.172.104:6010/api/request_otp",
         
         data : data  
       };
       try{
        const response=await axios.request(config)
       
        if(response.data.status===200){
            setWhatsappClose(false)
            setOtpState(true)
        }
        if(whatsapp.length===0){
            setErrorMessage("Please enter your Number")
            return
        }
      }
      catch (error) {
            toast.error("Something Went Wrong!");
    }


    }
    const handleOTP = async (e) =>{
        e.preventDefault();
        const data ={
            "on": "mobile",
            "otp": parseInt(otp),
            "mobile": whatsapp
        }
  const config = {
         method: "post",
         url: "http://68.178.172.104:6010/api/verify_otp",
         headers: { 
             'Authorization': `Bearer ${token}`,  
         },
         data : data  
       };
       try{
        const response=await axios.request(config)
        
        if(response.data.status===200){
            toast.success("Whatsapp verified successfully");
            setOtpMessage("Whatsapp verified successfully")
            setWhatsapp("")
            setOtp("")
            localStorage.setItem("whatsapp_verified",true)
           
            
        }
        else if(response.data.message==="No OTP generated"){
            setOtpErrorMessage("Enter your Whatsapp Number")
        }
        else {
            toast.error("Something Went Wrong!");
        }
      }
      catch (error) {
        toast.error("Something Went Wrong!");

    }
    

    }
    return ( <div>
         <Modal isOpen={props.state} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Whatsapp Verification</label>
                    <RxCross2 onClick={props.close} />
                </div>
                <div className="input-div-in-whatsapp-verify">
                    <input 
                        type="number" 
                        placeholder="Whatsapp Number"
                        className="input"
                        value={whatsapp}
                        onChange={(e)=>setWhatsapp(e.target.value)} />
                        <span  style={{color:'red',fontSize:'14px'}}>{errormessage}</span>

                </div>
                <div className="send-whatsapp-div">
                    <button onClick={handlewhatsapp} className="save-button">Send OTP</button>
                </div>

            </form>
        </Modal>
        <Modal isOpen={otpState} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        background:'white',
        width:'380px',
        height:'420px',
        maxHeight:'fit-content',
        padding:' 40px 30px',
        overflow:'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        },}}>
            <form>
                <div className='label-of-default-expense'>
                    <label className='lable-of-default-expense'>Whatsapp verification</label>
                    <RxCross2 onClick={()=>setOtpState(false)}/>
                </div>
                <div>
                    <input 
                        type='number' 
                        placeholder="OTP" 
                        className="input"
                        style={{margin:'50px 0px'}}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        />
                </div>
                    <span style={{fontSize:'14px',color:"#30B664",display:'flex',justifyContent:'center',marginBottom:'10px'}}>{otpMessage}</span>
                    <span style={{fontSize:'14px',color:"#30B664",display:'flex',justifyContent:'center',marginBottom:'10px'}}>{otpErrorMessage}</span>

                <div style={{display:'flex',justifyContent:'center'}}>
                    <button onClick={handleOTP} className="save-button">Verify OTP</button>
                </div>
            </form>
            <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
        </Modal>
    </div> );
}

export default Verify_whatsapp;