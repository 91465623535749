import {useEffect,useState,useRef} from 'react';
import { renderToString } from 'react-dom/server';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import './main_page.css'
import moment from 'moment';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { IoShareSocialOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CiMail } from "react-icons/ci";
import { FaFilePdf } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { useNavigate } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import './main_page.css';
import { Cards } from '../components/cards';
import { FaRegFilePdf } from "react-icons/fa6";
import { Toaster } from 'react-hot-toast';
import { ToastContainer, toast } from 'react-toastify';

function Home(){
  const token = localStorage.getItem('user')
  const navigate = useNavigate();
  const [income, setIncome]= useState("");
  const [expense, setExpense]= useState("");
  const [balance, setBalance]= useState("");
  const [savings, setSavings]= useState("");
  const [expensestable, setExpensestable] = useState([]);
  const [investtable, setInvesttable] = useState([]);
  const [statetable,setStatetable] = useState(0);
  const [expired, setExpired] = useState(false);
  const[currentMonth,setCurrentMonth]=useState();
  const [currentYear,setCurrentYear]=useState();
 const name = localStorage.getItem('name')
 const full_name = localStorage.getItem("full_name")
 const email =localStorage.getItem('email')
 const occupation = localStorage.getItem('occupation')
 const [loading, setLoading] = useState(false);

 const whatsapp = localStorage.getItem('whatsapp')
  const [showEmail,setShowEmail]=useState(false);
  const [showWs,setShowWs]=useState(false);
  useEffect(()=>{
    const fetchdata=async()=>{
    const config = {
           method: "get",
           url: "http://68.178.172.104:6010/api/Mainpage",
           headers: { 
               'Authorization': `Bearer ${token}`,  
           },  
         };
         
         try{
const response=await axios.request(config)

  setIncome(response.data.income)
  setExpense(response.data.total_expense)
  setBalance(response.data.balance)
  setSavings(response.data.total_savings)
  setExpensestable(response.data.All_Expenses)
  setInvesttable(response.data.investments)
  setCurrentMonth(response.data.current_month)
  setCurrentYear(response.data.current_year)

  setExpired(false);
}
  catch (error) {
    toast.error("Something Went Wrong!");
    // if (error.response.status === 401) {
    //   setExpired(true);
    // }
    // if(expired===true){
    //   alert("Session Expired! Please Login Again.")
    //   navigate("/login")
      
    // }
  }
        }
        fetchdata();
        
  },[]);
 


// const pdfRef = useRef();
// const pdftable = useRef();

 const handleShareEmail = (data) => {
//   const tableHtml = expensestable != null ? renderToString(
//     <Table responsive borderless>
//       <thead>
//         <tr>
//           <th className='th-of-expense-in-mainpage'>Expense</th>
//           <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
//           <th className='th-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>Amount</th>
//         </tr>
//       </thead>
//       <tbody>
//         {expensestable.map((item, index) => (
//           <tr key={index}>
//             <td className='td-of-expense-in-mainpage'>{item['name']}</td>
//             <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item['date']}</td>
//             <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>{item['amount']}</td>
//           </tr>
//         ))}
//         <tr>

//           <td colSpan={3} align='right' >Total amount : ₹{expense}</td>
//         </tr>
//       </tbody>
//     </Table>
//   ) : null;
//   const cardHtml =renderToString( 
//   <div>
//     <table style={{ width:'100%',tableLayout: 'fixed'}}>
//       <tr>
//         <td style={{ width: '50%',
//   paddingRight: '20px',
//   boxSizing: 'border-box'}}>Name: {full_name}</td>
//         <td style={{ width: '50%',
//   paddingLeft: '20px',
//   boxSizing: 'border-box'}}> Email         : {email} <br/> Whatsapp no.  : {whatsapp} <br/> Occupation    :{occupation} </td>
//       </tr>
//     </table><br/>
//     <div className='budget-for-month-text'>Budget for {moment().format('MMMM YYYY')}</div><br/>
//     <div className='flex-container'>
//      <div className='income-card'>
//       <div className='income-icon'>
//         <img src='Frame 427320431income.png' className='image-icon-on-mainpage'></img>
//       </div>
//       <div className='income-text'>Income</div>
//       <div className='amount-on-mainpage'>₹{income}</div>
//       <div class="hover-element"></div>
//     </div>
//     <div className='expense-card'>
//       <div className='expense-icon'>
//         <img src='Frame 427320431expense.png' className='image-icon-on-mainpage'></img>
//       </div>
//       <div className='expense-text'>Expenses</div>
//       <div className='amount-on-mainpage'>₹{expense}</div>
//     </div>
//     <div className='balance-card'>
//       <div className='balance-icon'>
//         <img src='Frame 427320431balance.png' className='image-icon-on-mainpage'></img>
//       </div>
//       <div className='balance-text'>Balance</div>
//       <div className='amount-on-mainpage'>₹{balance}</div>
//     </div>
//     <div className='savings-card'>
//       <div className='savings-icon'>
//         <img src='Frame 427320431savings.png' className='image-icon-on-mainpage'></img>
//       </div>
//       <div className='savings-text'>Savings</div>
//       <div className='amount-on-mainpage'>₹{savings}</div>
//     </div>
//   </div>
//   </div>)
//   const htmlContent = `<div>
//   ${cardHtml}
//   </div>  
//   <div id='table-responsive-for-mainpage' >
//   ${tableHtml !== null ? tableHtml : 'The Record is empty'}

// </div>
// </div>
// `;

// // Note: I've also replaced the dollar signs in the HTML content with template literals to interpolate the variable values.

   const generatePDFforEmail = async(to) => {
//     if (!htmlContent) {
//       alert('Please provide HTML content');
//       return;
//     }
  
//     // Options for html2pdf
//     const options = {
//       margin: 10,
//       filename: 'document.pdf',
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };
  
//     // Create a div container for the HTML content
//     const contentDiv = document.createElement('div');
//     contentDiv.innerHTML = htmlContent;
//     const pdfBlob = await html2pdf().from(contentDiv).set(options).output('blob');

    const formData = new FormData();
    
    formData.append("to",to)
    //formData.append('file',  new Blob([pdfBlob], { type: 'application/pdf' }), 'document.pdf');
    // Generate PDF and trigger download
    try {
      let config ={
        method: 'post',
        url: 'http://68.178.172.104:6010/api/share',
        headers: { 
          'Authorization': `Bearer ${token}`, 

        },
        data : formData
      }
      const response = await axios.request(config)
      if(response.data.message==="Email not verified." || (response.data.status==="failure" && to==="eamil")){
        setShowEmail(true)
      }
      if(response.data.message==="whatsapp not verified" || (response.data.status==="failure" && to ==="mobile")){
        setShowWs(true)
      }
   } catch (error) {
    toast.error("Failed to share via " + (to === "email" ? "email" : "WhatsApp"));
  }
  }
  if(data==='email'){
    generatePDFforEmail("email");
  }
  if(data==="mobile"){
    generatePDFforEmail("mobile");
  }
}
 const excelRef = useRef();

 const handleCreateExcel = () => {
    // const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.aoa_to_sheet([
    //   [ { v: 'Name', bgColor: { rgb: 'FF7F50' } }, { v: name, bgColor: { rgb: 'FF7F50' } } ],
    //   [ { v: 'Month', bgColor: { rgb: 'FF7F50' } }, { v: moment().format('MMMM'), bgColor: { rgb: 'FF7F50' } } ],
    //   [ { v: 'Year', bgColor: { rgb: 'FF7F50' } }, { v: moment().format('YYYY'), bgColor: { rgb: 'FF7F50' } } ],
    //   [ { v: 'Category', bgColor: { rgb: 'FF7F50' } }, { v: 'Income', bgColor: { rgb: 'FF7F50' } }, { v: 'Balance', bgColor: { rgb: 'FF7F50' } }, { v: 'Savings', bgColor: { rgb: 'FF7F50' } }, { v: 'Total Expense', bgColor: { rgb: 'FF7F50' } } ],
    //   [ { v: 'amount', bgColor: { rgb: 'FF7F50' } }, { v: income, bgColor: { rgb: 'FF7F50' } }, { v: balance, bgColor: { rgb: 'FF7F50' } }, { v: savings, bgColor: { rgb: 'FF7F50' } }, { v: expense, bgColor: { rgb: 'FF7F50' } } ],
    //   ["Expense","Date","Amount"],
    //   ...expensestable.map(({name, date, amount}) => [name, date, amount]),

      
    // ]);

    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, 'Budget_Planner.xlsx');
 };

    const handleLogin = (e)=>{
      navigate("/login")
    }
    
    const handleDownload = (format) => {
      const token = localStorage.getItem('user');
  
      fetch(`http://68.178.172.104:6010/api/Download?format=${format}`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      .then(response => {
          if (response.ok) {
              return response.blob();
          }
          throw new Error('Network response was not ok.');
      })
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `365SmartBudget.${format === 'pdf' ? 'pdf' : 'xlsx'}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);  
      })
      .catch(error => {
        toast.error("Failed to download the file.");

      });
  };
  

    return(
      <div>
          {token!=null?(<>
            <div className='mainpage-background'>
              <div className='title-of-mainpage'>
                    <div className='budget-for-month-text'>Budget for {moment().format('MMMM YYYY')}</div>
                      <div className='share-download-dropdown'>
                        <NavDropdown align="end" title={<button className="share"><IoShareSocialOutline />Share</button>} ><br/>
                          <NavDropdown.Item onClick={()=>handleShareEmail("mobile")}><FaWhatsapp /> Whatsapp</NavDropdown.Item><br/>
                          <NavDropdown.Item onClick={()=>handleShareEmail("email")} ><CiMail /> E-mail</NavDropdown.Item><br/>
                        </NavDropdown>

                        <Modal show={showWs} onHide={()=>setShowWs(false)} centered size='sm'>
                          <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                          </Modal.Header>
                          <Modal.Body>Whatsapp is not verified!</Modal.Body>
                          <Modal.Body>Whatsapp can be verified by: Go to settings -&gt; verify whatsapp</Modal.Body>
                          <Modal.Footer>
                            <Button className='save-button' style={{width:'110px',padding:'10px 30px'}} variant="secondary" onClick={()=>setShowWs(false)}>
                              Close
                            </Button>
              
                          </Modal.Footer>
                        </Modal>


                        <Modal show={showEmail} onHide={()=>setShowEmail(false)} centered size='sm'>
                          <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                          </Modal.Header>
                          <Modal.Body>Email is not verified!</Modal.Body>
                          <Modal.Body>Email can be verified by: Go to settings -&gt; verify Email</Modal.Body>
                          <Modal.Footer>
                            <Button className='save-button' style={{width:'110px',padding:'10px 30px'}} variant="secondary" onClick={()=>setShowEmail(false)}>
                              Close
                            </Button>
              
                          </Modal.Footer>
                        </Modal>  

                        <NavDropdown align="end"  title={<button className="download"><FaDownload />Download </button>} ><br/>
                          <NavDropdown.Item onClick={() => handleDownload('pdf')}><FaRegFilePdf /> Pdf</NavDropdown.Item><br/>
                          <NavDropdown.Item  onClick={() => handleDownload('xlsx')}><SiMicrosoftexcel/> Excel</NavDropdown.Item><br/>
                        </NavDropdown>
                      </div>
              </div>
              {/*<div className="flex-container">
              
              <Cards className="cards-instance" hover name="income"  amount={income}/>
              <Cards className="cards-instance" hover name="expense"  amount={expense}/>
              <Cards className="cards-instance" hover name="balance"  amount={balance}/>
              <Cards className="cards-instance" hover name="savings"  amount={savings}/>
          </div>*/}
          
              <div className="flex-container">
                <div className='income-card'>
                  <div className='income-icon'>
                  <img src='Frame 427320431income.png' className='image-icon-on-mainpage'></img>
                  </div>
                  <div className='income-text'>Income</div>
                  <div className='amount-on-mainpage'>{income}</div>
                  <div class="hover-element"></div>
                </div>
                <div className='expense-card'>
                  <div className='expense-icon'>
                    <img  src='Frame 427320431expense.png' className='image-icon-on-mainpage'></img>
                  </div>
                  <div className='expense-text'>Expenses</div>
                  <div className='amount-on-mainpage'>{expense}</div>
                </div>
                <div className='balance-card'>
                  <div className='balance-icon'>
                    <img src='Frame 427320431balance.png' className='image-icon-on-mainpage'></img>
                  </div>
                  
                  <div className='balance-text'>Balance</div>
                  <div className='amount-on-mainpage'>{balance}</div>
                </div>
                <div className='savings-card'>
                  <div className='savings-icon'>
                    <img src='Frame 427320431savings.png' className='image-icon-on-mainpage'></img>
                  </div>
                  
                  <div className='savings-text'>Savings</div>
                  <div className='amount-on-mainpage'>{savings}</div>
                </div>
          </div>
              <div className='Expenses-text-in-mainpage'>
                Expenses
              </div>
              <div className='bottom-content-of-mainpage' >
                <div className='nav-of-expense-detail-in-mainpage'>
                <Nav variant="pills" className="flex-column" id='nav-of-expense-in-mainpage'>
                  <Nav.Item>
                    <Nav.Link id="nav-link-on-mainpage" onClick={()=>setStatetable(0)} active={statetable===0} eventKey="all">All </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link id="nav-link-on-mainpage" onClick={()=>setStatetable(1)} active={statetable===1} eventKey="expense">Expenses</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link id="nav-link-on-mainpage" onClick={()=>setStatetable(2)} active={statetable===2} eventKey="investement">Investments </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item> */}
                    {/* <Nav.Link id="nav-link-on-mainpage" onClick={()=>setStatetable(3)} active={statetable===3} eventKey="savings">Savings</Nav.Link> */}
                  {/* </Nav.Item> */}
                </Nav>
                </div>
                {statetable===0?
                <div id='table-responsive-for-mainpage' >
                <Table responsive borderless  >
                 <tr >
                   <th className='th-of-expense-in-mainpage' id='expense-on-table-expense-in-mainpage'>Expense</th>
                   <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
                   <th className='th-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>Amount</th>
                 </tr>
               {(expensestable!= null && expensestable.length!=0) ?(expensestable.map((item, index) => (
                 <tr key={index} >
                   <td className='td-of-expense-in-mainpage' >{item['name']}</td>
                   <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item['date']}</td>
                   <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'> {item['amount']}</td>
                 </tr>
               ))):(<>No expenses recorded </>)}
             </Table>
             <Table responsive borderless>
                <tr>
                  <th className='th-of-expense-in-mainpage' id='expense-on-table-expense-in-mainpage'>Investment</th>
                  <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
                  <th className='th-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>Amount</th>
                </tr>
              
              {(investtable!= null && investtable.length!=0) ?(investtable.map((item, index) => (
                <tr key={index}>
                <td className='td-of-expense-in-mainpage'>{item["name"]}</td>
                <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item["date"]}</td>
                <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>{item["amount"]}</td>
                </tr>))):(<>No Investments recorded</>)}
             </Table>
             </div>
             :null }
             { statetable===1?
                <div id='table-responsive-for-mainpage' >
                <Table responsive borderless  >
                 <tr >
                   <th className='th-of-expense-in-mainpage' id='expense-on-table-expense-in-mainpage'>Expense</th>
                   <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
                   <th className='th-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>Amount</th>
                 </tr>
               {(expensestable!= null && expensestable.length!=0) ?(expensestable.map((item, index) => (!item.saving &&(
                 <tr key={index} >
                   <td className='td-of-expense-in-mainpage' >{item['name']}</td>
                   <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item['date']}</td>
                   <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'> {item['amount']}</td>
                 </tr>
               )))):(<>The Record is empty </>)}
             </Table>
             
             </div>
             :null }
             { statetable === 2 ?
             <div className='table-of-investment-in-mainpage' id='table-responsive-for-mainpage' >
             <Table>
                <tr>
                  <th className='th-of-expense-in-mainpage' id='expense-on-table-expense-in-mainpage'>Investment</th>
                  <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
                  <th className='th-of-expense-in-mainpage'id='amount-on-table-expense-in-mainpage'>Amount</th>
                </tr>
             
              {(investtable!= null && investtable.length!=0) ?(investtable.map((item, index) => (

                <tr key={index}>
                <td className='td-of-expense-in-mainpage' >{item["name"]}</td>
                <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item["date"]}</td>
                <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>{item["amount"]}</td>
                

                </tr>))):(<>There is no investment</>)}
             </Table>
             </div>:null}
             {/* {statetable === 3 ?
                <div id='table-responsive-for-mainpage' >
                <Table responsive borderless ref={pdftable} >
                 <tr >
                   <th className='th-of-expense-in-mainpage' id='expense-on-table-expense-in-mainpage'>Expense</th>
                   <th className='th-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>Date</th>
                   <th className='th-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'>Amount</th>
                 </tr>
               {expensestable!= null ?(expensestable.map((item, index) => (item.saving && (
                 <tr key={index} >
                   <td className='td-of-expense-in-mainpage' >{item['name']}</td>
                   <td className='td-of-expense-in-mainpage' id='date-on-table-expense-in-mainpage'>{item['date']}</td>
                   <td className='td-of-expense-in-mainpage' id='amount-on-table-expense-in-mainpage'> {item['amount']}</td>
                 </tr>
               )))):(<>The Record is empty </>)}
             </Table>
             
             </div>
             :null } */}
              </div>
            </div></>):(<><h1>please login</h1><button onClick={handleLogin}>login</button></>)}
            <ToastContainer
position="bottom-right"
autoClose={1000}
limit={3}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"

/>
      </div>
      
    )   
}
export default Home;