import React from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect,useState } from "react";
import axios from "axios";
import './Notification.css';
import { RxCross2 } from "react-icons/rx";
function Notification() {
    const [notification, setNotification] = useState("");
    const token = localStorage.getItem('user');
        useEffect(() => {
            const fetchdata=async()=>{
                const config = {
                    method: "post",
                    url: "http://68.178.172.104:6010/api/Notifications",
                    headers: { 
                        'Authorization': `Bearer ${token}`
                      }
                  };
                  const response = await axios.request(config);
                  
                  setNotification(response.data.Notifications)

            }
            fetchdata();
            
        }, []);
        const handleclear= async (id)=>{
            if (id !== null) {
                const newItems = notification.filter((item, index) => index !== id);
                
                setNotification(newItems);
                console.log(newItems)
                const data = new FormData();
            data.append('notifications',JSON.stringify(newItems));
            const config = {
                method: "post",
                url: "http://68.178.172.104:6010/api/Notifications",
                data:data,
                headers: { 
                    'Authorization': `Bearer ${token}`
                  },
                
              };
              const response = await axios.request(config);
              console.log(response.data)
            }
            

        }
        const handleClearAll = async ()=>{
            const newItems=[]
            setNotification([])
            const data = new FormData();
            data.append('notifications',JSON.stringify(newItems));
            const config = {
                method: "post",
                url: "http://68.178.172.104:6010/api/Notifications",
                data:data,
                headers: { 
                    'Authorization': `Bearer ${token}`
                  },
                
              };
              const response = await axios.request(config);
              console.log(response.data)

        }
    return ( 
    <div >
          <label className='label-of-dropdown-in-top-nav'><span style={{fontSize:'20px'}}>Notification</span><RxCross2/></label>
          <div style={{padding:'15px 30px'}}>
        {notification!=null && notification.length!==0?(<>
      
        {notification.map((notifications,index)=>(
        <div key={index} style={{background:'#F5F5F5',margin:'10px 0px',borderRadius:'10px'}}>
            <NavDropdown.Item  style={{display:'flex',justifyContent:'space-between',color:'black'}}>{notifications.reminder} </NavDropdown.Item>
            <span style={{display:'flex',justifyContent:'space-between',padding:'0px 16px'}} onClick={()=>handleclear(index)}>{notifications.date}<RxCross2/></span>
        </div>

        ))}<div style={{paddingTop:'15px',display:'flex',justifyContent:'center',alignItems:'center'}} ><button onClick={handleClearAll} className="logout-button" style={{padding:'8px 20px',width:'100px'}}>clear all</button></div></>):<div className="no-notification"><span>There is no Notification</span></div>}
</div>
    </div>
     );
}

export default Notification;