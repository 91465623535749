import ReminderTab from "../pages/reminder";
import { Col, Row } from "react-bootstrap";
import Sidenav from '../components/side_nav';
import Topnav from '../components/top_nav';
import Container from 'react-bootstrap/Container';

function Reminder() {
    return ( 
    <>
        <Container fluid>
        <Row>
            <Col md="auto"><Sidenav/></Col>
            <Col className='top-nav-bar'>
                <Topnav/>
                <ReminderTab/>
            </Col>
        </Row>
    </Container>
    </> );
}

export default Reminder;