import { useState } from 'react';
import Modal from 'react-modal';
import './expenseSubmit.css';
import { RxCross2 } from "react-icons/rx";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from "react-icons/fi";
import DataList from 'react-datalist-field';
import Select from 'react-select';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
function ExpenseForm(props) {
    const [startDate, setStartDate] = useState(new Date());

    const formatDate = (date) => {
       var dd = String(date.getDate()).padStart(2, '0');
       var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
       var yyyy = date.getFullYear();
   
       return dd + '-' + mm + '-' + yyyy;
    };
    const expenses = JSON.parse(props.default_expense)
    const activeExpenses = expenses
    .filter(reminder => reminder.Active)
    .map((expense, index) => ({
        name: expense.expense,
        code: expense.expense
    }));
    //const activeExpenses = expenses.filter(expense => expense.Active);

    // const selectOptions = activeExpenses.map(expense => ({
    // value: expense.expense,
    // label: expense.expense
    // }));
    
    if (activeExpenses.length === 0) {
      activeExpenses.push({
        name: "Enter Expenses",
        code: "Enter_Expenses",
        disabled: true
      });
    }
    return ( 
    
        <Modal isOpen={props.expensestate} contentLabel="Example Modal" ariaHideApp={false} style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    background:'white',
    width:'380px',
    height:'420px',
    padding:' 40px 30px',
    overflow:'none',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}}> 
            <form className='form-addExpense' >
                <div className='label-of-add-expense'>
                  <label>Add Expense</label>
                  <RxCross2 onClick={props.onClose} />
                  </div>
                  <div style={{marginTop:'30px'}}>
                    <></>
      <Dropdown 
        value={props.value} 
        onChange={props.expense} 
        options={activeExpenses} 
        optionLabel="name" 
        editable 
        placeholder="Expense" 
        className="w-full md:w-14rem" />
    </div>
                  <div>
                {/* <div className='total-input-dropdown' style={{marginTop:'30px',border: '1px solid var(--Blue-10, rgba(82, 104, 240, 0.10)))'}}>
                  <select className='select-expense'  onChange={props.expense}>
                    {console.log(expenses)}
                    <option disabled></option>
                  {expenses
                      .filter(expense => expense.Active)
                      .map((expense, index) => (
                          
                              <option  key={expense.expense}>
                                  {expense.expense}
                              </option>

                      ))
                  }
                  </select>
                  <input type="text" name="displayValue" id="displayValue" value={props.value} onChange={props.expense} placeholder="Name" />
                  <input name="idValue" id="idValue" type="hidden"/>
                </div> */}
                <span className="error-message">{props.expenseNameError}</span>
                <div style={{paddingTop:'30px'}}>
                  <DatePicker
                    placeholderText='Date'
                    showIcon
                    selected={props.startDate}
                    onChange={props.datechange}
                    dateFormat="dd-MM-yyyy"
                    className='input'
                    showMonthDropdown
                    showYearDropdown
                    required
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                      <path d="M13.5625 3.5H3.9375C3.17811 3.5 2.5625 4.11561 2.5625 4.875V14.5C2.5625 15.2594 3.17811 15.875 3.9375 15.875H13.5625C14.3219 15.875 14.9375 15.2594 14.9375 14.5V4.875C14.9375 4.11561 14.3219 3.5 13.5625 3.5Z" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M11.5 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 2.125V4.875" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2.5625 7.625H14.9375" stroke="#5268F0" stroke-width="1.375" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>}
                  />
                </div>
                  <span className="error-message">{props.expenseDateError}</span>
                <div style={{marginTop:'30px'}}>
                <input type='number' placeholder='Amount' className='input' onChange={props.amount} required/>
                </div>
                <span className="error-message">{props.expenseAmountError}</span>
              </div>
              <div style={{paddingTop:'30px'}}>
                <button className='save-button' onClick={props.onAdd}  >Save</button>
              </div>
            </form>
        </Modal>
 );
}

export default ExpenseForm;