import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './resetpass.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';

function Reset() {
  const [otp, SetOtp] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [successfulmessage, setSuccessfulmessage] = useState("");
  const [message, setMessage] = useState("");
  const [otpmessage, setOtpmessage] = useState("");
  const navigate = useNavigate();
  const [password, SetPassword] = useState("");
  const [confirmpassword, SetConfirmpassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const username = localStorage.getItem("username");

  const checkPasswordMatch = () => {
    if (password === confirmpassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    checkPasswordMatch();
  }, [confirmpassword]);

  const resetpassword = async (e) => {
    e.preventDefault();
    if (otp.length !== 6 || otp.length === 0) {
      setOtpmessage("Please enter a valid OTP.");
      return;
    }
    if (password.length < 8) {
      setMessage('Password must be greater than or equal to 8 characters');
      return;
    }
    let countUpperCase = 0;
    let countLowerCase = 0;
    let countDigit = 0;
    let countSpecialCharacters = 0;

    for (let i = 0; i < password.length; i++) {
      const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '[', '{', ']', '}', ':', ';', '<', '>',];
      if (specialChars.includes(password[i])) {
        countSpecialCharacters++;
      } else if (!isNaN(password[i] * 1)) {
        countDigit++;
      } else {
        if (password[i] === password[i].toUpperCase()) {
          countUpperCase++;
        }
        if (password[i] === password[i].toLowerCase()) {
          countLowerCase++;
        }
      }
    }

    if (countLowerCase === 0) {
      setMessage('0 lower case characters in password');
      return;
    }

    if (countUpperCase === 0) {
      setMessage('0 upper case characters in password');
      return;
    }

    if (countDigit === 0) {
      setMessage('0 digit characters in password');
      return;
    }

    if (countSpecialCharacters === 0) {
      setMessage('0 special characters in password');
      return;
    }
    const data = new FormData();
    data.append("userid", username);
    data.append("otp", otp);
    data.append('password', confirmpassword);
    const config = {
      method: "post",
      url: "http://68.178.172.104:6010/api/forget_password_verify",
      data: data,
    };
    try{
    const response = await axios.request(config);
    setSuccessfulmessage(response.data.message);
    localStorage.removeItem('username');
    setTimeout(() => {
      navigate("/");
    }, 5000);}
    catch (error) {
      toast.error("Something Went Wrong!"); 
    }
  };

  const back = () => {
    navigate('/login');
    localStorage.removeItem('username');
  };

  const emailback = () => {
    navigate("/pages/forgetpass.js");
  };

  return (
    <div className="total-login1">
      <div className='login-form'>
        <form className='form-op' method='post'>
          <p className='label' id='username'>Username:</p>
          <p>{username}</p>
          {username != null && <>
            <label className='label'>OTP:<input className='input' style={{ width: '374px' }} id='otp' type="number" value={otp} onChange={(e) => SetOtp(e.target.value)} placeholder='Enter OTP' /></label>
            <div className='error-style'>{otpmessage}</div>
            <label className='label'>Password:</label>
            <div style={{ position: 'relative', width: '374px' }}>
              <input
                className='input'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => SetPassword(e.target.value)}
                placeholder='Enter Password'
                style={{ paddingRight: '40px',width:'100%' }}
              />
              <i
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',color:'blue'
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </i>
            </div>
            <div className='error-style'>{message}</div>
            <label className='label'>Confirm Password:</label>
            <div style={{ position: 'relative', width: '374px' }}>
              <input
                className='input'
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmpassword}
                onChange={(e) => SetConfirmpassword(e.target.value)}
                placeholder='Confirm Password'
                style={{ paddingRight: '40px',width:'100%' }}
              />
              <i
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',color:'blue'
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </i>
            </div>
            <div className='error-style'>{passwordsMatch ? (<p> </p>) : (<p>Passwords do not match</p>)}</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <button className='save-button' style={{ padding: '15px 9px', width: '140px' }} onClick={resetpassword}>
                Reset Password
              </button>
              <button className='save-button' style={{ padding: '15px 9px', width: '140px' }} onClick={back}>
                Back
              </button>
              <div className='error-style'>{successfulmessage}</div>
            </div>
          </>}
          {username == null && <div><div>Please send OTP to your user ID first. Click below</div><button className='save-button' onClick={emailback} id='back'>Back</button></div>}
        </form>
      </div>
      <ToastContainer />

    </div>
  )
}

export default Reset;
